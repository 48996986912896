import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Button, Alert, Layout, Steps, InputNumber, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import "./BuyIndexPage.css";
import { getHostname } from "../location"
import { floatToDollars } from '../utils/numbers'

var ls = require("local-storage");


function BuyIndexPage() {
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [amount, setAmount] = useState(0);
  const buyingPower = ls.get('buying_power')
  const buyingPowerString = floatToDollars(buyingPower, false)
  const urlParams = new URLSearchParams(window.location.search)

  const history = useHistory();
  const hostName = getHostname()

  const routeChange = (path) => {
    history.push(path);
  }

  useEffect(() => {
    onFetchPreviewData()
  }, []);

  const onFetchPreviewData = () => {
    axios.get(`${getHostname()}/brokers/preview`, { params: { etf_name: urlParams.get('etf') }, withCredentials: true })
    .then(response => {
      setPreviewData(response.data)
    }).catch(error => {
      message.error('Error fetching stock positions')
    })
  }

  const onFinishBuyStocks = (values: any) => {
    setLoading(true)
    axios.post(`${hostName}/brokers/buy-saas`, { amount: parseFloat(dollarParser(values['amount'])), etf_name: urlParams.get('etf') }, { withCredentials: true })
    .then(response => {
      routeChange('/')
    }).catch(error => {
      if (error.response.status === 400 || error.response.status === 401) {
        const data = error.response.data
        message.error(data['message'] || 'Error buying stocks')
      } else {
        message.error('Error buying stocks')
      }
      setLoading(false)
    })
  }

  const dollarFormatter = value => {
    return `$ ${value}`
  }

  const dollarParser = value => {
    const valueWithDigits = value.replace(/[^\d.]/g, '').replace(/[.]/g, (i => m => !i++ ? m : '')(0))
    const indexOfDecimal = valueWithDigits.indexOf('.')
    if (indexOfDecimal == -1) {
      return valueWithDigits
    } else {
      return valueWithDigits.substring(0, indexOfDecimal + 3)
    }
  }

  const onChangeAmount = value => {
    setAmount(parseFloat(value || 0))
  }

  const onClickTogglePreview = () => {
    setShowPreview(!showPreview)
  }

  return (
    <Row className="App">
      <Col span={24}>
        <div className="Viewer">
          <div style={{ marginLeft: '16px', marginRight: '16px' }}>
            <h4 style={{ textAlign: "left", fontSize: '18px', lineHeight: '21px', marginBottom: '24px' }}>Hold Index</h4>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                style={{ border: 'none', backgroundColor: '#FFFFFF', marginLeft: '-8px' }}
                icon={<ArrowLeftOutlined style={{ color: '#175757' }}/>}
                onClick={history.goBack}
              />
            </div>
            <h2 style={{ flex: '1 1 0', fontWeight: '600', fontSize: '22px', lineHeight: '28px', marginBottom: '8px', textAlign: 'left' }}>Buy {previewData && previewData.display_name}</h2>
            <div style={{ fontSize: '13px', fontWeight: '400', lineHeight: '20px', color: '#747777', textAlign: 'left' }}>{buyingPowerString} buying power</div>
            <Form
              name="robinhood_buy"
              onFinish={onFinishBuyStocks}
            >
              <Form.Item
                name="amount"
                rules={[{ required: true, message: 'Please input an amount!' }]}
              >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', borderBottom: '1px solid #EDE7E7', marginTop: '28px', paddingBottom: '8px', marginBottom: '0px' }}>
                  <div className="amount-prefix-text">Dollar amount</div>
                    <InputNumber className="amount-input"
                      max={10000}
                      formatter={dollarFormatter}
                      parser={dollarParser}
                      style={{ border: 'none', width: "30%" }}
                      onChange={onChangeAmount}
                    />
                </div>
              </Form.Item>
              <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', textAlign: 'left', marginBottom: '24px' }}>
                We'll split the amount so you own the correct allocation of each stock.&nbsp;&nbsp;
                <div style={{ display: 'inline-block', cursor: 'pointer', color: '#175757', fontWeight: '600', fontSize: '15px', textAlign: 'left' }}
                  onClick={onClickTogglePreview}>
                  See breakdown {showPreview ? "⋀" : "⋁"}
                </div>
              </div>
              {previewData && showPreview && previewData.preview.map((preview, index) => {
                return (
                  <Row gutter={[16, 16]} style={{ borderBottom: '1px solid #EDE7E7', margin: '0px', textAlign: 'left' }}>
                    <Col span={10} className="company-column" style={{ paddingLeft: '0px' }}>
                      <div style={{ fontWeight: '400', fontSize: '12px', lineHeight: '20px', color: '#8E9393', textTransform: 'uppercase' }}>{preview.name}</div>
                      <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: '#8E9393' }}>{preview.ticker}</div>
                    </Col>
                    <Col span={8}>
                      <div style={{ fontWeight: '400', fontSize: '12px', lineHeight: '20px', color: '#8E9393', textTransform: 'uppercase' }}>Price</div>
                      <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: 'black' }}>{floatToDollars(preview.price, false)}</div>
                    </Col>
                    <Col span={4}>
                      <div style={{ fontWeight: '400', fontSize: '12px', lineHeight: '20px', color: '#8E9393', textTransform: 'uppercase' }}>Shares</div>
                      <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: 'black' }}>{(amount * preview.percentage / preview.price).toFixed(4)}</div>
                    </Col>
                  </Row>
                )
              })}
              <Button
                htmlType="submit"
                className="review-order-button"
                style={{ width: '100%', height: '40px', backgroundColor: '#175757', border: 'none', marginTop: '40px' }}
                loading={loading}
              >
                Submit order
              </Button>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default BuyIndexPage;
