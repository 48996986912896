import React, {Component} from  "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";

import Root from "./components/Root";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import Main from "./components/Main";
import BuyIndexPage from "./components/BuyIndexPage";
import LinkBrokerPage from "./components/LinkBrokerPage";


export const App = () => {
  const history = useHistory();

  return (
    <Router history={history}>
      <div>
        <Root>
          <Route exact path={"/login"} component={LoginPage} />    
          <Route path={"/dashboard"} component={Dashboard} />
          <Route exact path={"/"} component={Main} />
          <Route path={"/buy_index"} component={BuyIndexPage} />
          <Route path={"/link_broker"} component={LinkBrokerPage} />
        </Root>
      </div>
    </Router>
  )
}
