import React, { Component } from "react";
import axios from 'axios';
import { Row, Col, Form, Button, Alert, Layout, Steps, message } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Chart from "chart.js";
import Papa from 'papaparse';

import classes from "./DashboardGraph.css";


Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.legend.display = false;
Chart.defaults.global.elements.line.tension = 0;


export default class DashboardGraph extends Component {
  chartRef = React.createRef();

  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
    this.getData = this.getData.bind(this);
    this.chart = null;

    this.csvCache = {}
  }

  // fetchData = async () => {
  //   console.log('__dirname', process.cwd())
  //   const thispath = path.resolve('tmp', 'benchmarks.csv')
  //   console.log(thispath)
  //   const writer = fs.createWriteStream(thispath)

  //   return axios.get(
  //     'https://raw.githubusercontent.com/jonbma/holdsaas/master/webapp/src/data/benchmarks.csv?token=AB44G7FSMG5BQWIBKLZZTP3AFN3TW',
  //     { responseType: 'stream' }
  //   )
  //   .then(response => {
  //     console.log('fetched response body', response.body)
  //     response.data.pipe(writer)
  //     return writer
  //   }).catch(error => {
  //     console.log(error)
  //     console.log('[CHART ERROR] - unable to fetch benchmark data.')
  //   })
  // }
  getData(result) {
    this.setState({ data: result.data });
  }

  fetchCsv(selectedTimeframe) {
    return fetch(`/data/benchmarks-${selectedTimeframe}.csv`)
      .then(response => response.text());
  }

  async getCsvData(selectedTimeframe) {
    if (this.csvCache[selectedTimeframe]) {
      Papa.parse(this.csvCache[selectedTimeframe], {
        complete: this.getData
      });
    } else {
      let csvData = await this.fetchCsv(selectedTimeframe);
      Papa.parse(csvData, {
        complete: this.getData
      });

      this.csvCache[selectedTimeframe] = csvData;
    }
  }


  async componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");

    // const chartData = await this.fetchData()
    // console.log('fetched chart data: ', chartData)
    await this.getCsvData(this.props.selectedTimeframe)
    console.log('fetched chart data: ', this.state.data)

    const labels = this.state.data[0].slice(1).reverse()

    const benchmarkData = this.generateBenchmarkData()

    this.chart = new Chart(myChartRef, {
      type: "line",
      data: {
        // Bring in data
        // labels: ["JAN 20", "FEB 20", "MAR 20", "APR 20", "MAY 20", "JUN 20", "JUL 20", "AUG 20", "SEP 20", "OCT 20", "NOV 20", "DEC 20", "JAN 21"],
        labels,
        datasets: [
          {
            label: "Top 10 SaaS",
            //data: [910, 706, 850, 905, 750, 912, 900, 645, 905, 911, 954, 904, 900],
            data: benchmarkData[this.props.etf],
            fill: false,
            borderColor: "#175757",
            pointRadius: 0,
            borderWidth: 1,
          },
          {
            label: "EM Cloud",
            //data: [500, 490, 670, 600, 780, 440, 600, 680, 550, 475, 700, 795, 750],
            data: benchmarkData['WCLD'],
            fill: false,
            borderColor: "#8CD08B",
            pointRadius: 0,
            borderWidth: 1,
          },
          {
            label: "Nasdaq",
            //data: [600, 500, 600, 550, 700, 500, 600, 700, 500, 550, 600, 700, 650],
            data: benchmarkData['QQQ'],
            fill: false,
            borderColor: "#DC7124",
            pointRadius: 0,
            borderWidth: 1,
          },
          {
            label: "S&P 500",
            //data: [700, 500, 600, 550, 700, 500, 500, 700, 500, 550, 600, 700, 800],
            data: benchmarkData['SPY'],
            fill: false,
            borderColor: "#64B6F2",
            pointRadius: 0,
            borderWidth: 1,
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              fontColor: '#909293',
              fontSize: '8px',
              lineHeight: '10px'
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            ticks: {
              callback: function(value, index, values) {
                return value + "%";
              },
              fontColor: '#909293',
              fontSize: '8px',
              lineHeight: '10px'
            },
            gridLines: {
              borderDash: [1, 1],
              drawBorder: false
            }
          }]
        }
      }
    });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedTimeframe === this.props.selectedTimeframe)
      return

    const myChartRef = this.chartRef.current.getContext("2d");

    // const chartData = await this.fetchData()
    // console.log('fetched chart data: ', chartData)
    await this.getCsvData(this.props.selectedTimeframe)
    console.log('fetched chart data: ', this.state.data)

    const labels = this.state.data[0].slice(1).reverse()

    const benchmarkData = this.generateBenchmarkData()

    if (this.chart != null) {
      this.chart.destroy()
      this.chart = null
    }
    this.chart = new Chart(myChartRef, {
      type: "line",
      data: {
        // Bring in data
        // labels: ["JAN 20", "FEB 20", "MAR 20", "APR 20", "MAY 20", "JUN 20", "JUL 20", "AUG 20", "SEP 20", "OCT 20", "NOV 20", "DEC 20", "JAN 21"],
        labels,
        datasets: [
          {
            label: this.props.name,
            //data: [910, 706, 850, 905, 750, 912, 900, 645, 905, 911, 954, 904, 900],
            data: benchmarkData[this.props.etf],
            fill: false,
            borderColor: "#175757",
            pointRadius: 0,
            borderWidth: 1,
          },
          {
            label: "EM Cloud",
            //data: [500, 490, 670, 600, 780, 440, 600, 680, 550, 475, 700, 795, 750],
            data: benchmarkData['WCLD'],
            fill: false,
            borderColor: "#8CD08B",
            pointRadius: 0,
            borderWidth: 1,
          },
          {
            label: "Nasdaq",
            //data: [600, 500, 600, 550, 700, 500, 600, 700, 500, 550, 600, 700, 650],
            data: benchmarkData['QQQ'],
            fill: false,
            borderColor: "#DC7124",
            pointRadius: 0,
            borderWidth: 1,
          },
          {
            label: "S&P 500",
            //data: [700, 500, 600, 550, 700, 500, 500, 700, 500, 550, 600, 700, 800],
            data: benchmarkData['SPY'],
            fill: false,
            borderColor: "#64B6F2",
            pointRadius: 0,
            borderWidth: 1,
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              fontColor: '#909293',
              fontSize: '8px',
              lineHeight: '10px'
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            ticks: {
              callback: function(value, index, values) {
                return value + "%";
              },
              fontColor: '#909293',
              fontSize: '8px',
              lineHeight: '10px'
            },
            gridLines: {
              borderDash: [1, 1],
              drawBorder: false
            }
          }]
        }
      }
    });
  }

  floatToPercent(value) {
    if (!value) {
      return '+0.00%'
    }
    return (value > 0 ? '+' : '') + parseFloat(value).toFixed(2)
  }

  generateBenchmarkData() {
    const benchmarkData = {}

    for (let i = 1; i < this.state.data.length; i++) {
      benchmarkData[this.state.data[i][0]] = this.state.data[i].slice(1).reverse()
    }

    return benchmarkData
  }

  render() {
    const benchmarkData = this.generateBenchmarkData()

    return (
      <div>
        <div className={classes.graphContainer}>
          <canvas
            id="myChart"
            ref={this.chartRef}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '27px', alignItems: 'center', justifyContent: 'space-between', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ width: '16px', height: '2px', backgroundColor: '#175757', marginRight: '8px' }} />
            <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
              {this.props.name}
            </div>
          </div>
          <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
            {benchmarkData[this.props.etf] && this.floatToPercent(benchmarkData[this.props.etf].slice(-1)[0])}%
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ width: '16px', height: '2px', backgroundColor: '#8CD08B', marginRight: '8px' }} />
            <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
              EM Cloud
            </div>
          </div>
          <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
            {benchmarkData['WCLD'] && this.floatToPercent(benchmarkData['WCLD'].slice(-1)[0])}%
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ width: '16px', height: '2px', backgroundColor: '#DC7124', marginRight: '8px' }} />
            <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
              Nasdaq
            </div>
          </div>
          <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
            {benchmarkData['QQQ'] && this.floatToPercent(benchmarkData['QQQ'].slice(-1)[0])}%
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between', width: '240px', marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ width: '16px', height: '2px', backgroundColor: '#64B6F2', marginRight: '8px' }} />
            <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
              S&P 500
            </div>
          </div>
          <div style={{ fontSize: '15px', lineHeight: '22px', color: '#747777' }}>
            {benchmarkData['SPY'] && this.floatToPercent(benchmarkData['SPY'].slice(-1)[0])}%
          </div>
        </div>
      </div>
    )
  }
}
