export function getInvestmentThesis(etf, showMore) {
	if (etf === 'top_10') {
		return (
			<div>
				<div style={{fontWeight: 'bold'}}>
					Why the Top 10 SaaS Index
				</div>
				<div style={{marginTop: '8px'}}>
					<b>1) Adoption of cloud by businesses is still early! </b>Most companies are still building their own products in-house or using on-premise main frame servers — think of the server racks in HBO's Silicon Valley! 
				</div>
				<div style={{display: showMore ? 'block' : 'none'}}>
					<div style={{marginTop: '8px'}}>
						<b>2) COVID is a structrual tailwind so that companies NEED to use cloud products. </b>People now need video conferencing in order to get work done (Zoom), a simple to install security product for employees to work at home (Crowdstrike),  a digital solution to sign documents since you can't walk into your bosses office (Docusign), a way to sell stuff online since people can't go to their local mall (Shopify).
					</div>
					<div style={{marginTop: '8px'}}>
						<b>3) The leaders in a market typically grow faster and get bigger than other players. </b>Think about why Zoom was so widely used during the pandemic: Zoom became a verb and we were using it for work, birthday parties, catching up with friends. Same with Docusign: people are more likely to TRUST signing a legal doc because they've heard of Docusign before and so more companies will go with Docusign as their eSignature solution.
					</div>
					<div style={{marginTop: '8px'}}>
						<b>4) These companies have the best business metrics </b>which means they're growing fast, (mostly) profitable, AND are able to not lose customers and existing customers pay these companies MORE every year. 
					</div>
					<div style={{fontWeight: 'bold', marginTop: '8px'}}>
						Key Risk?
					</div>
					<div style={{marginTop: '8px'}}>
						<b>Valuations are HIGH: </b>software valuations are at historic high and could fall down. Most investors look at enterprise value/next-twelve-month revenue and its at nearly 30x for these high growth companies up nearly 3x in the last year.
					</div>
					<div style={{marginTop: '8px'}}>
						<b>BUT we believe marketing timing matters less — </b>what matters is picking the right company and continuing to dollar cost average on the way up or down. 
					</div>
					<div style={{marginTop: '8px'}}>
						<b>BECAUSE if you hold and invest for 3-5 years </b>a company growing 40% over a 5 year period will 5x their revenue. Even if the valuation multiples drop in half, you can still see a path to making 2.5x your money or 20% annualized returns.
					</div>
					<div style={{marginTop: '8px'}}>
						<b>ALSO we're diversifying among 10 companies: </b>picking a single company like Tesla (or Gamestop...) is risky but instead we're picking the 10 fastest growing companies that fit our criteria.
					</div>
				</div>
			</div>
		)
	}
	else if (etf === 'product_lead_growth') {
		return (
			<div>
				<div style={{fontWeight: 'bold'}}>
					Why invest in product led growth index
				</div>
				<div style={{marginTop: '8px'}}>
					<b>What are product led growth companies? </b>They're companies like Slack, Zoom, Calendly, Superhuman — built for the end user and grow virally through word of mouth. Instead of relying on an army of outbound sales teams, PLG companies focus on building products that end users love and can get started with a free trial or a swipe of a credit card
				</div>
				<div style={{display: showMore ? 'block' : 'none'}}>
					<div style={{marginTop: '8px'}}>
						<b>PLG companies grow faster and are valued more: </b>According to Public Comps, PLG companies grow faster, are more profitable, have higher retention, and are valued more than non-PLG companies at IPO. 
					</div>
					<div style={{marginTop: '8px'}}>
						<b>Product Led Growth is here to stay: </b>gone are the days of expensive and time consuming pilots and selling into the C-suite. Users are now ultimately the end buyer so companies are designing and building to delight the end user. 
					</div>
					<div style={{fontWeight: 'bold', marginTop: '8px'}}>
						Risks
					</div>
					<div style={{marginTop: '8px'}}>
						<b>High Valuation Multiples: </b>Just like most of these high growth SaaS companies, valuation mutliples are still at an all time high: these companies trade for 23.2x EV/ARRR (March 19th, 2021) up nearly 50% from a year ago. A potential mitigant is that COVID-19 accelerated digital transformation and the need for software products and justified the increase in multiples.
					</div>
				</div>
			</div>
		)
	}
	else if (etf === 'developer_tools') {
		return (
			<div>
				<div style={{fontWeight: 'bold'}}>
					Why invest in developer tools index
				</div>
				<div style={{marginTop: '8px'}}>
					<b>Software is eating the world and we need developers: </b>As software continues to eat the world and demand for software products grow, the constraint isn't the number of ideas but the number of developers to build the product
				</div>
				<div style={{display: showMore ? 'block' : 'none'}}>
					<div style={{marginTop: '8px'}}>
						<b>Dev tools make developers more productive: </b>There are so few developers and companies badly need them to build digital products and developer tools make the engineers more productive. 
					</div>
					<div style={{marginTop: '8px'}}>
						<b>Dev tools can experience consumer like growth: </b>Companies like Twilio can grow rapidly with their end customer: Instacart is a Twilio customer and grew its spend on Twilio as more and more consumers needed groceries delivered to them.
					</div>
					<div style={{fontWeight: 'bold', marginTop: '8px'}}>
						Risks
					</div>
					<div style={{marginTop: '8px'}}>
						<b>High Valuation Multiples: </b>Just like most of these high growth SaaS companies, valuation mutliples are still at an all time high: these companies trade for 29.3x EV/ARRR (March 7th, 2021) up nearly 2x from the 15x multiple a year ago. A potential mitigant is that COVID-19 accelerated digital transformation and the need for developer tools increased and justified the increase in multiples.
					</div>
				</div>
			</div>
		)
	}
	else if (etf === 'jon_ma') {
		return (
			<div>
				<div style={{fontWeight: 'bold'}}>
					Why invest in Jon Ma personal index
				</div>
				<div style={{marginTop: '8px'}}>
					<b>Been investing in early stage → public companies: </b>I've spent time investing in SaaS since 2014 from seed stage to public market companies. My philosophy is to invest in market leaders in large and growing markets with (mostly) founder-led companies that continue to ship and iterate quickly.
				</div>
				<div style={{display: showMore ? 'block' : 'none'}}>
					<div style={{marginTop: '8px'}}>
						<b>Focused on long term winners: </b>80% of my portfolio goes into 5 names: Crowdstrike, Twilio, Zoom, Shopify and Square. I'm a firm believer that the pandemic accelerated digital transformation and the adoption of video communication, e-commerce, digital payments, digital communication and cloud cybersecurity tools and they're here to stay.
					</div>
					<div style={{marginTop: '8px'}}>
						<b>Portfolio have the best business metrics: </b>on average the top 5 names are growing 94% y/y, >100% rule of 40, ~130% net dollar retention, are all profitable (~24% FCF margin). That means these businesses are not only growing fast but are profitable, with a lot of cash and are poised to continue to grow.
					</div>
					<div style={{fontWeight: 'bold', marginTop: '8px'}}>
						Risks
					</div>
					<div style={{marginTop: '8px'}}>
						<b>High Valuation Multiples: </b>Like most high growth SaaS companies, valuation mutliples are still quite high: these companies trade for 26x EV/ARRR (March 19th, 2021) up nearly 70% from a year ago. A potential mitigant is that COVID-19 accelerated digital transformation and the need for these digital products and justified the increase in multiples.
					</div>
				</div>
			</div>
		)
	}
	else if (etf === 'tiger_global') {
		return (
			<div>
				<div style={{fontWeight: 'bold'}}>
					Why invest in Tiger Global 13-F Index
				</div>
				<div style={{marginTop: '8px'}}>
					Tiger Global is considered one of the top performing hedgefunds in the world
				</div>
				<div style={{display: showMore ? 'block' : 'none'}}>
					<div style={{marginTop: '8px'}}>
						According to an investor letter, the firm’s gross internal rate of return across its 12 previous funds is 32%, while its net IRR is 24%.
					</div>
					<div style={{marginTop: '8px'}}>
						You can replicate the returns of Tiger Global's 13-F without having to pay the 2% annual fees and 20% fund performance that hedgefunds typically charge.
					</div>
					<div style={{fontWeight: 'bold', marginTop: '8px'}}>
						Risks
					</div>
					<div style={{marginTop: '8px'}}>
						<b>Doesn't include shorts: </b>We don't include any short positions as they aren't publicly available in the 13-Fs which increases risk in investing in these 13-Fs as we aren't privvy to the "hedge" managers may be rolling out. 
					</div>
					<div style={{marginTop: '8px'}}>
						<b>Lagging trades: </b>hedgefunds with >$100m of assets don't report their public holdings until within 45 days after a quarter-end. So any trade that is made since the reported 13-Fs won't be captured by our index.
					</div>
				</div>
			</div>
		)
	}
	return (
		<div>
		</div>
	)
}
