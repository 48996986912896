import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Button, Alert, Layout, Steps, message } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import DashboardGraph from "./DashboardGraph"

import "./Main.css";
import { getHostname } from "../location"
import { floatToDollars } from '../utils/numbers'

var ls = require("local-storage");
var classNames = require("classnames")

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


function Main(props) {
  const history = useHistory();
  const forceUpdate = useForceUpdate()

  const [robinhoodData, setRobinhoodData] = useState(null);
  const [dailyPerformance, setDailyPerformance] = useState(null);
  const [loadingRH, setLoadingRH] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState('ytd')

  const routeChange = (path) =>{ 
    history.push(path);
  }

  useEffect(() => {
    onFetchRobinhoodData()
  }, [props.location.pathname]);

  const onFetchRobinhoodData = () => {
    axios.get(`${getHostname()}/brokers`, { withCredentials: true })
    .then(response => {
      setRobinhoodData(response.data)
      onFetchDailyPerformanceData()
      setLoadingRH(false)
    }).catch(error => {
      if (error.response.status === 401) {
        routeChange('/login')
      }
      setRobinhoodData(null)
      setLoadingRH(false)
    })
  }

  const onFetchDailyPerformanceData = () => {
    axios.get(`${getHostname()}/etfs/daily_performance`, { withCredentials: true })
    .then(response => {
      setDailyPerformance(response.data)
    }).catch(error => {
      setDailyPerformance(null)
    })
  }

  // todo: mocked - fetch these from local storage for now
  var additionalPercentage = '0.00%'
  var gain = 0;

  if (robinhoodData !== null) {
    ls.set('buying_power', robinhoodData['buying_power'])

    const marketValue = robinhoodData['total_market_price']
    const costBasis = robinhoodData['total_cost_basis']
    gain = marketValue - costBasis
    if (costBasis === 0) {
      additionalPercentage = '0.00%'
    } else {
      additionalPercentage = ((marketValue / costBasis * 100) - 100.0).toFixed(2) + '%'
    }
  }

  if (loadingRH) {
    return <div />
  }
  return (
    <Row className="App">
      <Col span={24}>
        <div className="Viewer">
          <div style={{ marginLeft: '16px', marginRight: '16px' }}>
            <h4 style={{ textAlign: "left", fontSize: '18px', lineHeight: '21px', marginBottom: '30px' }}>HoldSaaS</h4>
            <div style={{ paddingBottom: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                <h2 style={{ fontSize: '28px', lineHeight: '32px', fontWeight: '800', marginBottom: '10px' }}>Investing</h2>
                <div style={{ fontSize: '28px', lineHeight: '32px', fontWeight: '400' }}>{robinhoodData !== null ? floatToDollars(robinhoodData['total_market_price'], false) : '$0.00'}</div>
                <div style={{ fontSize: '12px', lineHeight: '20px', fontWeight: '400', color: '#8E9393', marginTop: '4px' }}>{floatToDollars(gain, true)} ({additionalPercentage})</div>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #EDE7E7', paddingBottom: '20px' }}>
              <div style={{ backgroundColor: '#F7FBFA', paddingLeft: '16px', paddingRight: '16px', paddingTop: '24px', paddingBottom: '24px'}}>
                <img src="static/start-investing.png" />
                <div style={{ fontWeight: '400', color: '#404545' }} >
                  Start investing to see your portfolio grow<br/>
                  <a href="#stock-section" style={{ color: '#175757', fontWeight: '600' }}>Browse our tech indexes</a>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: '16px', paddingBottom: '18px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ fontWeight: '400', fontSize: '12px', lineHeight: '20px', textTransform: 'uppercase', color: '#8E9393' }}>
              Buying Power
              </div>
              <div style={{ fontWeight: '500', fontSize: '15px', lineHeight: '20px', color: '#1C2020'}}>
                {robinhoodData !== null ? floatToDollars(robinhoodData['buying_power'], false) : '$0.00'}
              </div>
            </div>

            <div style={{ paddingBottom: '24px' }}>
              <div style={{ backgroundColor: '#F7FBFA', padding: '16px' }}>
                <div style={{ fontWeight: '400', color: '#404545' }} >
                  Link your brokerage accounts so you can start buying our top 10 SaaS index right away.
                </div>
              </div>
              <Button
                onClick={() => routeChange('/link_broker')}
                className="review-order-button"
                style={{ width: '100%', height: '40px', backgroundColor: '#175757', color: '#FFFFFF',border: 'none', marginTop: '24px' }}
              >
                Link brokerage account
              </Button>
            </div>
            <div style={{ borderBottom: '4px solid #EDE7E7', marginLeft: '-16px', marginRight: '-16px'}} >
            
            </div>
            
            <div>
              <h4 id="stock-section" style={{ paddingTop: '16px', fontWeight: '500', fontSize: '18px', lineHeight: '21px', textAlign: 'left', marginBottom: '6px' }}>Tech indexes</h4>
              {dailyPerformance && dailyPerformance.map((position, index) => {
                return (
                  <Row 
                    onClick={() => routeChange(`/dashboard?etf=${position.id}`)}
                    gutter={[16, 16]}
                    style={{ borderBottom: '1px solid #EDE7E7', margin: '0px', textAlign: 'left', cursor: 'pointer' }}
                  >
                    <Col span={17} className="company-column" style={{ paddingLeft: '0px', marginTop: 'auto', marginBottom: 'auto', padding: '0px' }}>
                      {position.name}
                    </Col>
                    <Col span={7} style={{ paddingRight: '0px', paddingTop: '16px', paddingBottom: '16px' }} >
                      <div style={{ backgroundColor: '#ECF2F2', fontWeight: '600', color: '#175757', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', textAlign: 'center', borderRadius: '4px' }}>
                        {position.performance > 0 ? '↑ ' : '↓ '}{(Math.abs(100 * position.performance)).toFixed(2)}%
                      </div>
                    </Col>
                  </Row>
                )
              })}
              <Row gutter={[16, 16]} style={{ margin: '0px', textAlign: 'left' }}>
                <Col span={17} className="company-column" style={{ color: '#175757', fontWeight: '600', paddingLeft: '0px', marginTop: 'auto', marginBottom: 'auto', padding: '0px' }}>
                  ⊕ Create your own
                </Col>
                <Col span={7} style={{ paddingRight: '0px', paddingTop: '16px', paddingBottom: '16px' }} >
                  <div style={{ fontWeight: '400', color: '#8A8E8E', textAlign: 'center' }}>
                    coming soon
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Main;
