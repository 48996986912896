import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Button, Alert, Layout, Steps, message } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import DashboardGraph from "./DashboardGraph"

import "./Dashboard.css";
import { getHostname } from "../location"
import { floatToDollars } from '../utils/numbers'
import { getInvestmentThesis } from './investmentThesis'


var ls = require("local-storage");
var classNames = require("classnames")

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


function Dashboard(props) {
  const history = useHistory();
  const forceUpdate = useForceUpdate()

  const [positionData, setPositionData] = useState(null);
  const [loadingRH, setLoadingRH] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState('ytd')
  const [showMore, setShowMore] = useState(false);
  const urlParams = new URLSearchParams(window.location.search)

  const routeChange = (path) =>{ 
    history.push(path);
  }

  useEffect(() => {
    onFetchPositionData()
  }, []);

  const onFetchPositionData = () => {
    axios.get(`${getHostname()}/brokers/positions`, { params: { etf_name: urlParams.get('etf') }, withCredentials: true })
    .then(response => {
      setPositionData(response.data)
      setLoadingRH(false)
    }).catch(error => {
      setPositionData(null)
      setLoadingRH(false)
    })
  }

  var positions = []
  var orders = []
  var name = ''
  if (positionData !== null) {
    positions = positionData['positions']
    orders = positionData['orders']
    name = positionData['name']
  }

  if (loadingRH) {
    return <div />
  }

  return (
    <Row className="App">
      <Col span={24}>
        <div className="Viewer">
          <div style={{ marginLeft: '16px', marginRight: '16px' }}>
            <h4 style={{ textAlign: "left", fontSize: '18px', lineHeight: '21px', marginBottom: '30px' }}>HoldSaaS</h4>
            <div style={{ textAlign: 'left' }}>
              <Button
                style={{ border: 'none', backgroundColor: '#FFFFFF', textAlign: 'left' }}
                icon={<ArrowLeftOutlined style={{ color: '#175757' }}/>}
                onClick={() => { routeChange('/') }}
              />
            </div>
            <div style={{ paddingBottom: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                <h2 style={{ fontSize: '28px', lineHeight: '32px', fontWeight: '800', marginBottom: '0px' }}>{name}</h2>
              </div>
            </div>
            <div style={{ paddingBottom: '32px' }}>
              <Row gutter={[10, 10]} style={{ marginLeft: '0px', marginBottom: '14px' }}>
                <Col span={3.5} style={{ padding: '5px 5px 5px 0px' }}>
                  <Button
                    className={classNames({'graph-timeframe-button': true, 'selected-timeframe': selectedTimeframe === '3m'})}
                    onClick={() => setSelectedTimeframe('3m')}
                  >
                    3M
                  </Button>
                </Col>
                <Col span={3.5}>
                  <Button
                    className={classNames({'graph-timeframe-button': true, 'selected-timeframe': selectedTimeframe === '6m'})}
                    onClick={() => 
                      {
                        console.log('we here')
                        setSelectedTimeframe('6m')
                        forceUpdate()
                      }
                    }
                  >
                    6M
                  </Button>
                </Col>
                <Col span={3.5}>
                  <Button
                    className={classNames({'graph-timeframe-button': true, 'selected-timeframe': selectedTimeframe === '1y'})}
                    onClick={() => setSelectedTimeframe('1y')}
                  >
                    1Y
                  </Button>
                </Col>
                <Col span={3.5}>
                  <Button
                    className={classNames({'graph-timeframe-button': true, 'selected-timeframe': selectedTimeframe === 'ytd'})}
                    onClick={() => setSelectedTimeframe('ytd')}
                  >
                    YTD
                  </Button>
                </Col>
                <Col span={3.5}>
                  <Button
                    className={classNames({'graph-timeframe-button': true, 'selected-timeframe': selectedTimeframe === '2y'})}
                    onClick={() => setSelectedTimeframe('2y')}
                  >
                    2Y
                  </Button>
                </Col>
                <Col span={3.5}>
                  <Button
                    className={classNames({'graph-timeframe-button': true, 'selected-timeframe': selectedTimeframe === '5y'})}
                    onClick={() => setSelectedTimeframe('5y')}
                  >
                    5Y
                  </Button>
                </Col>
                <Col span={4} style={{ padding: '5px 0px 5px 5px' }}>
                  <Button
                    className={classNames({'graph-timeframe-button': true, 'selected-timeframe': selectedTimeframe === 'max'})}
                    onClick={() => setSelectedTimeframe('max')}
                  >
                    MAX
                  </Button>
                </Col>
              </Row>
              <DashboardGraph style={{ height: '150px' }} selectedTimeframe={selectedTimeframe} etf={urlParams.get('etf')} name={name} />
            </div>
            <div>
              {orders.length > 0 && (<div style={{ paddingBottom: '32px' }}>
                <h4 style={{ fontWeight: '500', fontSize: '18px', lineHeight: '21px', textAlign: 'left' }}>History</h4>
                {orders.map((order, index) => {
                  return (
                    <>
                      <Row gutter={[32, 32]} style={{ borderBottom: '1px solid #EDE7E7', margin: '0px', textAlign: 'left' }}>
                        <Col span={18} className="company-column" style={{ paddingLeft: '0px' }}>
                          <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: '#404545' }}>Market Buy</div>
                          <div style={{ fontWeight: '400', fontSize: '13px', lineHeight: '20px', color: '#8A8E8E', paddingTop: '4px' }}>{order.status}</div>
                        </Col>
                        <Col span={4}>
                          <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: '#404545' }}>{floatToDollars(-order.amount, true)}</div>
                        </Col>
                      </Row>
                    </>
                  )
                })}
              </div>)}

              <div style={{ paddingBottom: '32px' }}>
                <h4 style={{ fontWeight: '500', fontSize: '18px', lineHeight: '21px', textAlign: 'left', marginBottom: '16px' }}>Investment thesis</h4>
                <div style={{textAlign: 'left'}}>
                  {getInvestmentThesis(urlParams.get('etf'), showMore)}
                </div>
                <div style={{ color: '#175757', fontWeight: '600', paddingLeft: '0px', marginTop: '8px', marginBottom: 'auto', padding: '0px', cursor: 'pointer', textAlign: 'left', width: 'fit-content' }} onClick={() => setShowMore(!showMore)}>
                  {showMore ? 'Read less' : 'Read more'}
                </div>
              </div>
              
              <h4 style={{ fontWeight: '500', fontSize: '18px', lineHeight: '21px', textAlign: 'left' }}>Companies</h4>
              {positions.map((position, index) => {
                return (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '16px' }}>
                      <div style={{ borderRadius: '10px 0px 0px 10px', width: `calc(100% * ${parseFloat(position.percent)})`, height: '8px', backgroundColor: '#175757' }} />
                      <div style={{ fontWeight: '400', fontSize: '13px', lineHeight: '20px', marginLeft: '8px' }}>{`${(parseFloat(position.percent) * 100).toFixed(2)}%`}</div>
                    </div>
                    <Row gutter={[16, 16]} style={{ borderBottom: '1px solid #EDE7E7', margin: '0px', textAlign: 'left' }}>
                      <Col span={10} className="company-column" style={{ paddingLeft: '0px' }}>
                        <div style={{ fontWeight: '400', fontSize: '12px', lineHeight: '20px', color: '#8E9393', textTransform: 'uppercase' }}>{position.symbol}</div>
                        <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: '#8E9393' }}>{position.name}</div>
                      </Col>
                      <Col span={8}>
                        <div style={{ fontWeight: '400', fontSize: '12px', lineHeight: '20px', color: '#8E9393', textTransform: 'uppercase' }}>Price</div>
                        <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: 'black' }}>{position.price !== null ? floatToDollars(position.price, false) : '-'}</div>
                      </Col>
                      <Col span={4}>
                        <div style={{ fontWeight: '400', fontSize: '12px', lineHeight: '20px', color: '#8E9393', textTransform: 'uppercase' }}>Allocation</div>
                        <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', color: 'black' }}>{`${(parseFloat(position.percent) * 100).toFixed(2)}%`}</div>
                      </Col>
                    </Row>
                  </>
                )
              })}
            </div>
          </div>
          <div style={{ position: 'fixed', bottom: '0px', width: '375px', backgroundColor: '#FFFFFF', boxShadow: 'inset 0px 2px 0px #E7EAEA', height: '80px', marginTop: '36px', paddingTop: '20px', paddingBottom: '20px', paddingRight: '15px', textAlign: 'right' }}>
            <Button
              style={{ height: '40px', width: '122px', borderRadius: '3px', backgroundColor: '#175757' }}
              onClick={() => routeChange(`/buy_index?etf=${urlParams.get('etf')}`)}
            >
              <div style={{ color: 'white', fontWeight: '500', fontSize: '15px', lineHeight: '20px' }}>Buy</div>
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Dashboard;
