import React, { useState } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Button, Layout, Steps, Input, message } from 'antd';
import { ArrowLeftOutlined, EyeTwoTone, EyeInvisibleOutlined, CheckCircleOutlined } from '@ant-design/icons';


import "./LinkBrokerPage.css";
import { getHostname } from "../location"
import { floatToDollars } from '../utils/numbers'


var ls = require("local-storage");


function LinkBrokerPage() {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [challengeId, setChallengeId] = useState(null);
  const [status, setStatus] = useState(null);
  const [buyingPower, setBuyingPower] = useState(0);

  const routeChange = (path) => {
    history.push(path);
  }

  const fetchBuyingPower = () => {
    axios.get(`${getHostname()}/brokers`, { withCredentials: true })
    .then(response => {
      setBuyingPower(response.data['buying_power'])
      setCurrentStep(4)
    })
    .catch(error => {
      message.error(error.response.data.message)
    })
  }

  const onFinishRobinhoodLogin = (values: any) => {
    console.log('Success:', values);

    const usernameInput = values['username'] || username
    const passwordInput = values['password'] || password
    const smsCode = values['sms_code']
    const mfaCode = values['mfa_code']
    const params = {
      email: usernameInput,
      password: passwordInput
    }
    if (challengeId) {
      params['challenge_id'] = challengeId
    }
    if (smsCode) {
      params['challenge_code'] = smsCode
    }
    if (mfaCode) {
      params['mfa_code'] = mfaCode
    }
    if (deviceId) {
      params['device_id'] = deviceId
    }
    axios.post(`${getHostname()}/brokers/auth`, params, { withCredentials: true })
    .then(response => {
      fetchBuyingPower()
    }).catch(error => {
      if (error.response.status === 400) {
        const data = error.response.data
        if (data['mfa_required']) {
          setUsername(usernameInput)
          setPassword(passwordInput)
          setDeviceId(data['device_id'])
          setCurrentStep(3)
        } else if (data['challenge_required']) {
          setUsername(usernameInput)
          setPassword(passwordInput)
          setChallengeId(data['challenge_id'])
          setDeviceId(data['device_id'])
          setCurrentStep(2)
        }
      } else {
        message.error(error.response.data.message)
      }
    })
  };
  const link_broker_steps = [
    {
      stepNumber: 0,
      title: 'Welcome! Connect your brokerage account',
      content: 
        <div>
          <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', textAlign: 'left', marginBottom: '23px' }}>Choose your broker. You'll be asked for login credentials next.</div>
          <Button
            className="select-robinhood-button"
            style={{ float: 'left', backgroundColor: 'white', width: '164px', height: '110px', border: 'none', borderRadius: '4px', boxShadow: "0px 2px 8px #DADEDE" }}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            <img src='/static/robinhood-logo.png'/>
          </Button>
        </div>
    },
    {
      stepNumber: 1,
      title: 'Enter your Robinhood login',
      content: 
        <div>
          <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', textAlign: 'left', marginBottom: '23px' }}>Your information is stored securely with us.</div>
            <Form
              name="robinhood_login"
              onFinish={onFinishRobinhoodLogin}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input className="username-input"
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password className="password-input"
                  placeholder="Password"
                  style={{ marginBottom: "24px" }}
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="continue-button" style={{ width: '100%', height: '40px', backgroundColor: '#175757', border: 'none' }}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
        </div>
    },
    {
      stepNumber: 2,
      title: 'Enter SMS Code',
      content: 
        <div>
          <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', textAlign: 'left', marginBottom: '23px' }}>We've just sent you a one-time verification code.</div>
          <Form
            name="robinhood_login"
            onFinish={onFinishRobinhoodLogin}
          >
            <Form.Item
              name="sms_code"
              rules={[{ required: true, message: 'Please input the sms code!' }]}
            >
              <Input className="sms-input"
                style={{ marginBottom: "24px" }}
                placeholder="SMS code"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="continue-button" style={{ width: '100%', height: '40px', backgroundColor: '#175757', border: 'none' }}
              >
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </div>
    },
    {
      stepNumber: 3,
      title: 'Enter Pin Code',
      content: 
        <div>
          <div style={{ fontWeight: '400', fontSize: '15px', lineHeight: '22px', textAlign: 'left', marginBottom: '23px' }}>Enter your multi-factor authentication code.</div>
          <Form
            name="robinhood_login"
            onFinish={onFinishRobinhoodLogin}
          >
            <Form.Item
              name="mfa_code"
              rules={[{ required: true, message: 'Please input the mfa code!' }]}
            >
              <Input className="sms-input"
                style={{ marginBottom: "40px" }}
                placeholder="Pin code"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="continue-button" style={{ width: '100%', height: '40px', backgroundColor: '#175757', border: 'none' }}
              >
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </div>
    },
    {
      stepNumber: 4,
      title: 'Your Robinhood account is successfully linked!',
      content: 
        <div>
          <div style={{ textAlign: 'left', fontWeight: '400', fontSize: '15px', lineHeight: '22px', marginBottom: '24px' }}>Your Robinhood buying power has been added to HoldSaaS.</div>
          <div style={{ borderBottom: '1px solid #EDE7E7', marginTop: '20px' }} />
          <div style={{ paddingBottom: '16px', paddingTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ textTransform: 'uppercase', fontSize: '12px', lineHeight: '20px', color: '#8A8E8E' }}>Buying Power</div>
            <div style={{ fontSize: '15px', fontWeight: '500', lineHeight: '22px', color: 'black' }}>{floatToDollars(buyingPower, false)}</div>
          </div>
          <div style={{ borderBottom: '1px solid #EDE7E7', marginBottom: '40px' }} />
          <Button
            className="continue-button" style={{ width: '100%', height: '40px', backgroundColor: '#175757', border: 'none' }}
            onClick={() => {
              routeChange('/')
            }}
          >
            Go back to home
          </Button>
        </div>
    }
  ]

  return (
    <Row className="App">
      <Col span={24}>
        <div className="Viewer">
          <div style={{ marginLeft: '16px', marginRight: '16px' }}>
            <h4 style={{ textAlign: "left", fontSize: '18px', lineHeight: '21px', marginBottom: '30px' }}>HoldSaaS</h4>
            <div style={{ display: (currentStep > 0) || (currentStep < 4) ? '' : 'none', textAlign: 'left' }}>
              {currentStep < 4 && <Button
                style={{ border: 'none', textAlign: 'left' }}
                icon={<ArrowLeftOutlined style={{ color: '#175757' }}/>}
                onClick={() => {
                  if (currentStep == 3) {
                    setCurrentStep(1)
                  } else if (currentStep > 0) {
                    setCurrentStep(currentStep - 1)
                  } else {
                    routeChange('/')
                  }
                }}
              />}
              {currentStep === 4 && <CheckCircleOutlined style={{ color: '#175757' }} />}
            </div>
            <h3 style={{ flex: '1 1 0', fontWeight: '700', fontSize: '22px', lineHeight: '28px', marginBottom: '16px', textAlign: 'left' }}>{link_broker_steps[currentStep].title}</h3>
            {link_broker_steps[currentStep].content}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default LinkBrokerPage;
