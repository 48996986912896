import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { loginUser } from '../services/magic';
import './LoginPage.css';

import { Form, Input, Button, message, Row, Col } from 'antd';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import { getHostname } from "../location"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState('');
  const [error, setError] = useState(null);

  const history = useHistory();

  const handleSubmit = async (values) => {
    const email = values['email']
    setLoading(true);
    if (!email) {
      setLoading(false);
      message.error('Email is Invalid');
      return;
    }
    try {
      const didToken = await loginUser(email);
      axios.post(`${getHostname()}/v1/users/signup-or-login`,
        { data: { email } },
        { withCredentials: true, headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + didToken } }
      )
      .then(response => {
        setLoading(false);
        if (response.data.user_has_broker) {
          history.push('/')
        } else {
          history.push('/link_broker')
        }
      })
      .catch(error => {
        setLoading(false);
        message.error('Unable to log in to HoldSaaS.');
      })
    } catch (error) {
      setLoading(false);
      message.error('Unable to log in via Magic.');
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row className="App">
      <Col span={24}>
        <div className="Viewer" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          {!loading ?
            <div>
              <h4 style={{ textAlign: "left", fontSize: '18px', lineHeight: '21px' }}>HoldSaaS</h4>
              <h3 style={{ textAlign: "left", fontSize: '22px', lineHeight: '28px', fontWeight: '700', marginTop: '30px' }}>Sign in or sign up</h3>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'This field is required' }]}
                  style={{ marginBottom: '40px' }}
                >
                  <Input
                    className="email-input"
                    style={{
                      marginTop: '24px',
                      marginBottom: '0px',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }}
                    placeholder="Email address"
                  />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: "#175757",
                      borderRadius: '3px',
                      width: '100%',
                      height: '40px',
                      color: 'white',
                      fontWeight: '500',
                      fontSize: '15px',
                      lineHeight: '20px'
                    }}
                  >
                    <div>Continue</div>
                  </Button>
                </Form.Item>
              </Form>
            </div>
            :
            <div>
              <h4 style={{ textAlign: "left", fontSize: '18px', lineHeight: '21px' }}>HoldSaaS</h4>
              <div style={{ textAlign: "left", marginTop: '30px' }}>
                <CheckCircleOutlined style={{ color: '#175757', fontSize: '20px', marginBottom: '8px' }} />
                <h3 style={{ fontSize: '22px', lineHeight: '28px', fontWeight: '700', marginBottom: '40px' }}>Check your inbox for a sign-in link</h3>
              </div>
              <div style={{ textAlign: "left", fontSize: '15px', lineHeight: '22px', fontWeight: '400' }}>
                Haven't received it yet? Please wait 10 minutes, then refresh page to resubmit.
              </div>
            </div>
          }
        </div>
      </Col>
    </Row>
  );
}


export default LoginPage;
