import { Magic } from 'magic-sdk';

var magic_public_key = 'pk_live_094EE56EFB77051D'
if (process.env.REACT_APP_ENV !== 'prod') {
	magic_public_key = 'pk_test_EBD859E548ADF013'
}
const magic = new Magic(magic_public_key);

export const checkUser = async (cb) => {
  const isLoggedIn = await magic.user.isLoggedIn();
  if (isLoggedIn) {
    const user = await magic.user.getMetadata();
    return cb({ isLoggedIn: true, email: user.email });
  }
  return cb({ isLoggedIn: false });
};

export const loginUser = async (email) => {
  return await magic.auth.loginWithMagicLink({ email, showUI: false });
};

export const logoutUser = async () => {
  await magic.user.logout();
};
